{
  "notFound": "404 - Not Found",
  "traducteur": "",
  "contact-us": "",
  "head": {
    "meta": {
      "description": "This test consists of some questions (don't count them) about the topics of alcohol, sex, and drugs...",
      "title": "Purity Test"
    }
  },
  "menu": {
    "test-purete": "Purity Test",
    "random-shitpost": "random-shitpost.com"
  },
  "home-page": {
    "title": "Purity Test",
    "subtitle": "This test consists of some questions (don't count them) about the topics of alcohol, sex, and drugs.",
    "intro-text": {
      "line-1": "Some of these questions may be offensive to some.",
      "line-2": "If your are of those, do not go further and close this window...",
      "line-3": "Otherwise..."
    },
    "beware": "Please don't go back during the test and good luck !!",
    "ad-block": {
      "title": "⬇️Discover our new useless website⬇️"
    },
    "begin-griffor-btn": "👉 Classic purity test !",
    "begin-boudah-btn": "Alternative version !",
    "continue-btn": "Continue where I left",
    "ad-warning": {
      "warning": "☝️ Warning, if an ad show up, go back ☝️",
      "our-test": "(our test is and will",
      "always": "always be",
      "free": "free) 😇"
    },
    "already": "Already ",
    "tests-made": " tests passed ! 🎉"
  },
  "possibleAnswers": {
    "oui": "yes",
    "non": "no"
  },
  "questions": [
    {
      "id": "riMalheur",
      "question": "Have you ever laughed at someone's misfortune?"
    },
    {
      "id": "riMental",
      "question": "Have you ever laughed at someone with a mental or physical disability?"
    },
    {
      "id": "poserLapin",
      "question": "Have you ever stood someone up?"
    },
    {
      "id": "foisArrete",
      "question": "How many times have you ever been arrested?"
    },
    {
      "id": "gouterAlcool",
      "question": "Have you ever tasted alcohol?"
    },
    {
      "id": "dejaIvre",
      "question": "Have you ever been drunk?"
    },
    {
      "id": "ivre48h",
      "question": "Have you ever been drunk for more than 48 hours?"
    },
    {
      "id": "tomberIvre",
      "question": "Have you ever fallen because you were too drunk?"
    },
    {
      "id": "pisserDessus",
      "question": "Have you ever peed on yourself when you were drunk?"
    },
    {
      "id": "participerBoire",
      "question": "Have you ever participated in drinking games?"
    },
    {
      "id": "buVomir",
      "question": "Have you ever drunk enough to throw up?"
    },
    {
      "id": "vomiQuelquun",
      "question": "Have you ever vomited on yourself or someone else?"
    },
    {
      "id": "virerNuit",
      "question": "Have you ever been kicked out of a bar or a nightClub?"
    },
    {
      "id": "barathon",
      "question": "Have you ever participated in a barathon? (All the bars in a city or a street)?"
    },
    {
      "id": "alcoolDejeuner",
      "question": "Have you ever had an alcoholic breakfast?"
    },
    {
      "id": "alcool3fois",
      "question": "Do you drink alcohol regularly (at least three times a week)?"
    },
    {
      "id": "jourPlus",
      "question": "Number of days in addition ?"
    },
    {
      "id": "dormiToilette",
      "question": "Have you ever slept in the toilet?"
    },
    {
      "id": "volontaireToilette",
      "question": "...Were you volunteer to sleep there?"
    },
    {
      "id": "evanouiBar",
      "question": "Have you ever fallen asleep in a bar or a club?"
    },
    {
      "id": "avalerVomi",
      "question": "Have you ever eaten your own or someone else's vomit?"
    },
    {
      "id": "vomiLieuPublic",
      "question": "Have you ever vomited in a public place?"
    },
    {
      "id": "urinerPasPrevu",
      "question": "Have you ever urinated in a place that was not intended for this use?"
    },
    {
      "id": "essayerDrogue",
      "question": "Have you ever tried drugs?"
    },
    {
      "id": "drogueEssaye",
      "question": "Indicate the number of drugs tried:"
    },
    {
      "id": "defonce48h",
      "question": "Have you ever been high for more than 48 hours?"
    },
    {
      "id": "drogueRegulier",
      "question": "Do you take drugs regularly?"
    },
    {
      "id": "plus4fois",
      "question": "More than 4 times a week ?"
    },
    {
      "id": "acheteDrogue",
      "question": "Have you ever bought drugs?"
    },
    {
      "id": "venduDrogue",
      "question": "Have you ever sold drugs?"
    },
    {
      "id": "venduDrogueFinance",
      "question": "Have you ever sold drugs to finance your own use?"
    },
    {
      "id": "badTrip",
      "question": "Have you ever had a bad trip?"
    },
    {
      "id": "initierDrogue",
      "question": "Have you ever introduced someone to drugs in general?"
    },
    {
      "id": "profiterQuelquun",
      "question": "Have you ever taken advantage of someone while they were drunk, drugged, or temporarily disabled?"
    },
    {
      "id": "sextape",
      "question": "Have you ever made a sextape ?"
    },
    {
      "id": "rdvAmoureux",
      "question": "Have you ever had a date?"
    },
    {
      "id": "embrasseQuelquun",
      "question": "Have you ever kissed/touch someone?"
    },
    {
      "id": "embrasserOrgasme",
      "question": "Have you ever kissed/touch someone to the orgasm?"
    },
    {
      "id": "relationsSexuelles",
      "question": "Have you ever had sex?"
    },
    {
      "id": "payerSexe",
      "question": "Have you ever paid for sex?"
    },
    {
      "id": "sexeArgent",
      "question": "Have you ever had sex for money?"
    },
    {
      "id": "impliqueFellation",
      "question": "Have you ever been involved in oral sex or cunnilingus?"
    },
    {
      "id": "jusquaOrgasme",
      "question": "Until orgasm?"
    },
    {
      "id": "rapportAnal",
      "question": "Have you ever had anal sex?"
    },
    {
      "id": "jusquaOrgasme2",
      "question": "Until orgasm?"
    },
    {
      "id": "fait69",
      "question": "Have you ever done a 69?"
    },
    {
      "id": "ist",
      "question": "Have you ever had an STD (sexually transmitted disease)?"
    },
    {
      "id": "sansContraception",
      "question": "Have you ever had sex without using contraception?"
    },
    {
      "id": "avortement",
      "question": "Have you ever had or caused an abortion?"
    },
    {
      "id": "plusDunePersonne",
      "question": "Have you ever had sex with more than one person at the same time?"
    },
    {
      "id": "plusDeuxPersonneSemaine",
      "question": "Have you ever had sex with more than two people in the same week?"
    },
    {
      "id": "sexeLieuPublic",
      "question": "Have you ever had sex in a public place?"
    },
    {
      "id": "blessureSexe",
      "question": "Have you ever had injuries from sex?"
    },
    {
      "id": "homo",
      "question": "Have you ever had homosexual intercourse (or sex contrary to your sexual orientation)?"
    },
    {
      "id": "bdsm",
      "question": "Have you ever had sadomasochistic experiences or practiced bondage?"
    },
    {
      "id": "sextoys",
      "question": "Have you ever used sex toys?"
    },
    {
      "id": "endormiSexe",
      "question": "Have you ever fell asleep or fainted during sex?"
    },
    {
      "id": "perteVirginite",
      "question": "Have you ever been responsible for the loss of someone's virginity, and if so, how many?"
    },
    {
      "id": "acheterSexShop",
      "question": "Have you ever bought something from a sex shop?"
    },
    {
      "id": "sexeFamilial",
      "question": "Have you ever had sex with someone in your family?"
    },
    {
      "id": "hesiter",
      "question": "Did you hesitate before answering the previous question?"
    },
    {
      "id": "zoophilieToussa",
      "question": "Does zoophilia, necrophilia or pedophilia appeal to or turn you on?"
    },
    {
      "id": "coupSoir",
      "question": "Have you ever had a one night stand?"
    },
    {
      "id": "combienCoupSoir",
      "question": "How many times?"
    },
    {
      "id": "sexeSansAurevoir",
      "question": "Have you ever had a one night stand, and left your partner without even saying goodbye?"
    },
    {
      "id": "sexeMemeFamille",
      "question": "Have you ever had sex with more than one person from the same family?"
    },
    {
      "id": "sexeDejaRelation",
      "question": "Have you ever had sex with someone who was already in a relationship with someone else?"
    },
    {
      "id": "sexeMarie",
      "question": "Was this person married?"
    },
    {
      "id": "sexeAmiPartenaire",
      "question": "Have you ever had sex with a friend of your official partner?"
    },
    {
      "id": "sexeDeuxFoisVieux",
      "question": "Have you ever had sex with someone who was twice as old as you?"
    },
    {
      "id": "bainMembreOppose",
      "question": "Have you ever taken a bath or a shower with a member of the opposite sex?"
    },
    {
      "id": "lecherOeil",
      "question": "Have you ever licked an eye, a toe, or an ear?"
    },
    {
      "id": "dejaMasturbation",
      "question": "Have you ever masturbated?"
    },
    {
      "id": "masturbationTelephone",
      "question": "Have you ever masturbated while on the phone?"
    },
    {
      "id": "masturbationDeux",
      "question": "Mutual masturbation?"
    },
    {
      "id": "doucheDoree",
      "question": "Have you ever participated in a golden shower (urinating on someone else or being urinated on by someone else)?"
    },
    {
      "id": "reveErotique",
      "question": "Have you ever had an erotic dream?"
    },
    {
      "id": "voyeur",
      "question": "Have you ever been a voyeur?"
    },
    {
      "id": "simulerOrgasme",
      "question": "Have you ever faked an orgasm?"
    },
    {
      "id": "sortiSexe",
      "question": "Have you ever dated someone just for sex?"
    },
    {
      "id": "sexePari",
      "question": "Have you ever had sex to win a bet?"
    },
    {
      "id": "sexeCoupDeVent",
      "question": "Have you ever had sex on the go (between classes, in the street, all in a hurry, with little or no foreplay)?"
    },
    {
      "id": "sexePromotion",
      "question": "Have you ever had sex to get a job or a promotion?"
    },
    {
      "id": "intimePublic",
      "question": "Have you ever exhibited your genitals in public?"
    },
    {
      "id": "indelite",
      "question": "Have you ever been unfaithful to your partner?"
    },
    {
      "id": "sexePartenaireAmi",
      "question": "Have you ever had sex with the partner of a friend of yours?"
    },
    {
      "id": "sexeRegret",
      "question": "Have you ever had sex that you now regret?"
    },
    {
      "id": "telephoneSexe",
      "question": "Have you ever called or taken a phone call while having sex?"
    },
    {
      "id": "teleSexe",
      "question": "Have you ever watched TV while having sex?"
    },
    {
      "id": "dessinAnimeSexe",
      "question": "Have you ever wondered about the sex life of cartoon characters?"
    },
    {
      "id": "ouSuisJe",
      "question": "Have you ever woken up wondering where You are?"
    },
    {
      "id": "ouSuisJeAvecQui",
      "question": "Have you ever woken up wondering where You are and who the person next to you is?"
    },
    {
      "id": "tatouer",
      "question": "Do you have a tattoo?"
    },
    {
      "id": "travaillerCaritative",
      "question": "Have you ever worked for a charity?"
    },
    {
      "id": "possedeBible",
      "question": "Do you own a bible?"
    },
    {
      "id": "donCaritative",
      "question": "Have you ever donated to a charity?"
    },
    {
      "id": "lieuCulte",
      "question": "Have you ever been to Church other than for weddings, communions or funerals?"
    },
    {
      "id": "lieuCulteUneSemaine",
      "question": "Do you go to church at least once a week?"
    },
    {
      "id": "ptitfilou",
      "question": "Did you take any of the acts of this test to improve your score?"
    }
  ],
  "results": {
    "global": {
      "you-are": "You are",
      "your-score-is": "Your score is",
      "points": "point(s)",
      "reponses-positives": "Positive answers",
      "moyenne-line": "Scores can range from {minScore} to {maxScore}, average is {moyenne} (on {testsNumber} tests)"
    },
    "purity-levels": {
      "tres-pur": "VERY PURE",
      "pur": "PURE",
      "plutot-pur": "MOSTLY PURE",
      "impur": "IMPURE",
      "salement-impur": "FRANKLY IMPUR",
      "dangereux-impur": "HORRIBLE (AND IMPUR)",
      "ignoble-impur": "INSANE (AND IMPUR)"
    },
    "category": {
      "sex": "Sex",
      "alcohol": "Alcohol",
      "drugs": "Drugs",
      "hygiene": "Hygiene",
      "moral": "Moral",
      "positives": "Positives"
    },
    "phrases": {
      "negatif": [
        "Don't forget your basket when you'll go to the market after the mass, next sunday...",
        "(Your score is negative: this means that You are either a very pure person or too young.)"
      ],
      "10": [
        "For you, the nuns are still too perverse!",
        "(You are considered a very pure person with little experience, perhaps by choice or by lack of maturity ;-))"
      ],
      "50": [
        "Stop surviving and try to live!",
        "(Do what you want with your life, the thing is to never regret anything!)"
      ],
      "100": [
        "Yeah, you use your right (or left) hand to jerk off, just like everyone else!",
        "(An average score, nothing very transcendent)"
      ],
      "150": [
        "Just above average. All we can tell you is that you've been out of your house a few times!",
        "(A good start ! You don't have to be ashamed!)"
      ],
      "200": [
        "Life is like a vast playground for you!",
        "(Your past has been full of surprises and it is not over... far from it!)"
      ],
      "250": [
        "You live you life without any fear !",
        "(A score which gives you the right to proclaim your impurity to everyone :P)"
      ],
      "400": [
        "Yeah... We will try the straightjacket. Everything's fine, the guys in white coats will take care of everything.",
        "(Do what you want with your life, the thing is to never regret anything!)"
      ],
      "max": [
        "Closer to animals than humans... If you could lick your genitals, you would no longer leave your home.",
        "(Boo so disgusting!)"
      ]
    },
    "twitter": "I scored {score} points on the Purity Test #griffor ! And you? :)"
  }
}
